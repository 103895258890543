<template>
  <div class="visitor-check-in-settings-container">
    <i class="ri-settings-3-fill" @click="showSettings = !showSettings"></i>
    <ul
      :class="{
        'visitor-loading': isLoading
      }"
      v-if="showSettings"
    >
      <div class="px-2 py-1 d-flex justify-content-between align-items-center">
        <div class="capture-label">Capture via</div>
        <i class="ri-close-line" @click="showSettings = !showSettings"></i>
      </div>
      <li
        v-for="option in menuOptions"
        :key="option.key"
        @click="onDeviceSelect(option.key)"
      >
        <i :class="option.iconClass"></i>
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import visitorConstants from "@/constants/visitorConstants"
import { ref } from "vue"

export default {
  name: "VisitorCaptureViaMenu",
  emits: ["notify"],
  setup(_props, { emit }) {
    const showSettings = ref(false)
    const isLoading = ref(false)

    const menuOptions = [
      {
        key: visitorConstants.VISITOR_CAMERA_TYPES.ANDROID,
        name: "Android",
        iconClass: "ri-checkbox-blank-line me-2",
        show: false
      },
      {
        key: visitorConstants.VISITOR_CAMERA_TYPES.IOS,
        name: "App",
        iconClass: "ri-checkbox-blank-line me-2",
        show: true
      },
      {
        key: visitorConstants.VISITOR_CAMERA_TYPES.COMPUTER,
        name: "Computer",
        iconClass: "ri-computer-line me-2",
        show: true
      }
    ].filter((option) => option.show)

    const onDeviceSelect = async (name) => {
      const map = {
        android: visitorConstants.VISITOR_CAMERA_TYPES.ANDROID,
        ios: visitorConstants.VISITOR_CAMERA_TYPES.IOS,
        computer: visitorConstants.VISITOR_CAMERA_TYPES.COMPUTER
      }
      localStorage.setItem(
        visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.DEFAULT_CAMERA,
        map[name]
      )
      showSettings.value = false

      if (
        [
          visitorConstants.VISITOR_CAMERA_TYPES.IOS,
          visitorConstants.VISITOR_CAMERA_TYPES.ANDROID
        ].includes(map[name])
      ) {
        emit("notify", name)
      }
    }

    return {
      isLoading,
      menuOptions,
      showSettings,
      onDeviceSelect
    }
  }
}
</script>

<style scoped></style>
